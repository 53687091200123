<template>
  <div class="news_list">
    <div class="columns">
      <div
        @click="type = item.id"
        :class="'column' + (type == item.id ? ' active' : '')"
        v-for="(item, index) in columns"
        :key="index"
      >
        <div class="box">{{ item.name }}</div>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="news-list">
        <div
          class="list"
          v-show="type == item.id"
          v-for="(item, index) in columns"
          :key="index"
        >
          <div class="news-box">
            <div class="item" v-for="item in list" :key="item.articleId">
              <div class="pic">
                <router-link
                  :to="{ name: 'news_detail', params: { id: item.articleId } }"
                >
                  <img src="@/assets/images/image4.png" alt="" />
                </router-link>
              </div>
              <div class="info">
                <div class="title">
                  <router-link
                    :to="{ name: 'news_detail', params: { id: item.articleId } }"
                  >
                    {{ item.title }}
                  </router-link>
                </div>
                <p>{{ item.remark }}</p>
                <div class="tips">
                  <div class="time">
                    <my-icon :styles="{ fontSize: '18px' }" type="iconzhong" />
                    {{ item.createTime }}
                  </div>
                  <div class="click">
                    <my-icon
                      :styles="{ fontSize: '25px', marginTop: '-2px' }"
                      type="icondianjiliang"
                    />
                    {{ item.view }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <custom-page
            @change="changePage"
            :current="current"
            :total="total"
          ></custom-page>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/news";
const columns = [
  { name: "焦点新闻", id: 0 },
  { name: "政策文件", id: 1 },
  { name: "通知公告", id: 2 },
];
export default {
  data() {
    return {
      columns,
      current: 1,
      total: 0,
      list: [],
      type: 0,
      loading: false,
    };
  },
  watch: {
    type() {
      this.getList();
    },
  },
  components: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      Api.list({ pageSize: 10, pageNum: 1, newsType: this.type }).then(
        (res) => {
          this.list = res.result.records;
          this.loading = false;
        }
      );
    },
    changePage(page) {
      this.current = page;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.columns {
  display: flex;
  width: 100%;
  .column {
    flex: 1;
    padding: 0 5px;
    cursor: pointer;
    .box {
      background: #f9f9f9;
      text-align: center;
      line-height: 41px;
      border-top: 2px solid transparent;
    }
    &.active {
      .box {
        border-top: 2px solid #008840;
      }
    }
  }
}
.news-list {
  margin-top: 20px;
  .news-box {
    .item {
      display: flex;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px dashed #e8e8e8;
      .info {
        padding-left: 15px;
        .title {
          font-size: 18px;
          margin-bottom: 10px;
          a {
            color: #333;
          }
        }
        p {
          font-size: 16px;
          color: #9e9e9e;
        }
        .tips {
          display: flex;
          font-size: 14px;
          color: #9e9e9e;
          .click {
            margin-left: 30px;
          }
        }
      }
    }
  }
}
</style>
